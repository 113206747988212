import { ETextStyleVariant, Text } from '@outdoorsyco/bonfire';
import React from 'react';
import { useIntl } from 'react-intl';

import { defaultLocale, TCurrency } from '@/config/locales';
import { formatCurrency } from '@/utility/currency';
import { ICampgroundPrice } from '@/utility/mapSearchResultToTile';

type TRentalPriceProps = ICampgroundPrice & {
  currency?: TCurrency;
};

export const CampgroundPrice = ({
  currency = defaultLocale.base_currency,
  lowestPrice,
}: TRentalPriceProps) => {
  const intl = useIntl();
  const formattedLowestPrice = formatCurrency({
    currency,
    digits: 0,
    maxDigits: 0,
    priceInCents: lowestPrice,
  });

  return (
    <div className="flex items-center">
      <Text
        component="span"
        variant={ETextStyleVariant.LargeBold}
        aria-label={intl.formatMessage(
          {
            description: '[Price]: screen reader message for current price per night',
            defaultMessage: '{currentPrice} per night',
          },
          {
            currentPrice: formattedLowestPrice,
          },
        )}
        className="contents">
        {intl.formatMessage(
          {
            description: '[Price]: current price formatting for a listing',
            defaultMessage:
              '<priceTemplate>from {value}</priceTemplate><periodTemplate>/night</periodTemplate>',
          },
          {
            priceTemplate: currentPriceTemplate,
            periodTemplate: periodTemplate,
            value: formattedLowestPrice,
          },
        )}
      </Text>
    </div>
  );
};

const currentPriceTemplate = (value: string): React.ReactElement => (
  <span aria-hidden className="order-2">
    {value}
  </span>
);

const periodTemplate = (period: string): React.ReactElement => (
  <span aria-hidden className="order-4 ml-1">
    {period}
  </span>
);
