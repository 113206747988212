import React from 'react';
import { useIntl } from 'react-intl';

import { BOLT } from '@/components/switchback/Icon/assets';
import Icon from '@/components/switchback/Icon/IconComponent';

import { PriceSize } from '../Price';
import css from './PriceInstantBook.module.css';

type IInstantBookElement = React.HTMLAttributes<HTMLElement>;

interface IProps {
  className?: string;
  size?: PriceSize;
  testId?: string;
}

const PriceInstantBook: React.FC<IProps & IInstantBookElement> = ({
  className = '',
  size = PriceSize.medium,
  testId = 'price-instant-book',
}) => {
  const intl = useIntl();

  const screenReaderMessage = intl.formatMessage({
    description: '[Price]: screen reader message for instant book',
    defaultMessage: 'This listing allows instant booking.',
  });

  return (
    <span
      aria-label={screenReaderMessage}
      className={`${css.wrapper} ${className}`}
      role="img"
      data-testid={testId}
      data-size={size}>
      <Icon name={BOLT} width="100%" height="100%" />
    </span>
  );
};

export default PriceInstantBook;
