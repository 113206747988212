import { ETextStyleVariant, Text } from '@outdoorsyco/bonfire';
import React from 'react';
import { useIntl } from 'react-intl';

import { PriceSize } from '@/components/switchback/Price';
import PriceCurrent from '@/components/switchback/Price/PriceCurrent';
import PriceInstantBook from '@/components/switchback/Price/PriceInstantBook';
import { defaultLocale, TCurrency } from '@/config/locales';
import { calcDiscount } from '@/redux/selectors/listing/bill';
import { PriceType } from '@/redux/selectors/listing/page';
import { formatCurrency } from '@/utility/currency';

export interface TPriceDetailsProps {
  currency?: TCurrency;
  originalPrice: number;
  currentPrice: number;
  instantBook?: boolean;
  period: PriceType;
  size?: PriceSize;
  showDiscount: boolean;
}

export const PriceDetails = ({
  currency = defaultLocale.base_currency,
  originalPrice,
  currentPrice,
  instantBook = false,
  period,
  size = PriceSize.small,
  showDiscount,
}: TPriceDetailsProps) => {
  const hasDiscount = currentPrice < originalPrice;
  const discount = calcDiscount(originalPrice, currentPrice);

  const intl = useIntl();

  const formattedPrice = formatCurrency({
    currency,
    ceil: true,
    digits: 0,
    maxDigits: 0,
    priceInCents: originalPrice,
  });

  const screenReaderMessage = intl.formatMessage(
    {
      description: "[Price]: listing's original price, before discount",
      defaultMessage: 'Original price was {value}',
    },
    {
      value: formattedPrice,
    },
  );

  return (
    <div className="flex flex-col justify-center gap-1">
      <div className="flex">
        {instantBook && <PriceInstantBook className="self-center" />}
        <PriceCurrent
          size={size}
          currency={currency}
          currentPrice={currentPrice}
          period={period}
          isCampsite
        />
      </div>
      {showDiscount && hasDiscount && (
        <div className="flex text-primary-730">
          <Text
            variant={ETextStyleVariant.SmallRegular}
            aria-label={screenReaderMessage}
            className={instantBook ? `ml-5 line-through` : `line-through`}>
            {formattedPrice}
          </Text>

          <Text variant={ETextStyleVariant.SmallRegular} className="ml-2">
            {intl.formatMessage(
              {
                defaultMessage: '({value}% off)',
                description: '[Price]: discount message',
              },
              { value: discount },
            )}
          </Text>
        </div>
      )}
    </div>
  );
};
