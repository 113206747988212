import React from 'react';

import { defaultLocale, TCurrency } from '@/config/locales';
import { IPrice } from '@/utility/mapSearchResultToTile';

import PriceCurrent from './PriceCurrent';
import PriceDiscount from './PriceDiscount';
import PriceInstantBook from './PriceInstantBook';
import PriceOriginal from './PriceOriginal';

type IPriceElement = React.HTMLAttributes<HTMLElement>;

export enum PriceSize {
  small = 'small',
  medium = 'medium',
}

export interface IProps extends IPrice {
  isCampground?: boolean;
  currency?: TCurrency;
  showDiscount?: boolean;
  showOriginalPrice?: boolean;
  size?: PriceSize;
  testId?: string;
}

const Price: React.FC<IProps & IPriceElement> = ({
  isCampground,
  size = PriceSize.medium,
  currency = defaultLocale.base_currency,
  discount,
  instantBook = false,
  originalPrice,
  currentPrice,
  overrideDayPrice = 0,
  discountFromOverrideDayPrice = 0,
  showDiscount = isCampground ? discountFromOverrideDayPrice > 0 : discount > 0,
  showOriginalPrice = isCampground ? currentPrice < overrideDayPrice : currentPrice < originalPrice,
  testId = 'price',
  period,
}) => {
  const hasDiscount = isCampground ? currentPrice < overrideDayPrice : currentPrice < originalPrice;

  return (
    <div
      className={`flex text-gray-900 items-baseline mt-1 ${
        showOriginalPrice ? 'flex-col ' : ''
      }lg:flex-row`}
      data-testid={testId}>
      <div className="flex items-baseline lg:contents">
        {instantBook && (
          <PriceInstantBook className="self-center" size={size} testId={`${testId}-instant-book`} />
        )}
        <PriceCurrent
          size={size}
          currency={currency}
          currentPrice={currentPrice}
          period={period}
          testId={`${testId}-current`}
        />
      </div>
      <div className="flex items-baseline lg:contents">
        {showOriginalPrice && hasDiscount && (
          <PriceOriginal
            size={size}
            currency={currency}
            originalPrice={(isCampground && overrideDayPrice) || originalPrice}
            testId={`${testId}-original`}
          />
        )}
        {showDiscount && hasDiscount && (
          <PriceDiscount
            size={size}
            discount={(isCampground && discountFromOverrideDayPrice) || discount}
            testId={`${testId}-discount`}
          />
        )}
      </div>
    </div>
  );
};

export default Price;
