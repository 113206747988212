import React from 'react';
import { useIntl } from 'react-intl';

import { PriceSize } from '../Price';
import css from './PriceDiscount.module.css';

type IDiscountElement = React.HTMLAttributes<HTMLElement>;

interface IProps {
  size?: PriceSize;
  discount: number;
  testId?: string;
}

const PriceDiscount: React.FC<IProps & IDiscountElement> = ({
  size = PriceSize.medium,
  discount,
  testId = 'price-discount',
}) => {
  const intl = useIntl();

  return (
    <p className={css.wrapper} data-testid={testId} data-size={size}>
      {intl.formatMessage(
        {
          defaultMessage: '({value}% off)',
          description: '[Price]: discount message',
        },
        { value: discount },
      )}
    </p>
  );
};

export default PriceDiscount;
