import { ETextStyleVariant, SkeletonLine, Text } from '@outdoorsyco/bonfire';
import React, { Fragment } from 'react';

import generateTestIdFrom from '@/utility/generateTestIdFrom';
import { IRentalDetails } from '@/utility/mapSearchResultToTile';

type TRentalInfoProps = IRentalDetails & {
  loading?: boolean;
  isStay?: boolean;
  isCampground?: boolean;
  campgroundSubtitle?: string;
  title?: string;
  hideDetails?: boolean;
};

export const RentalInfo = ({
  loading,
  isStay,
  isCampground,
  campgroundSubtitle,
  title,
  classText,
  feet,
  sleeps,
  beds,
  maxVehicleLength,
  seats,
  location,
  hideDetails,
}: TRentalInfoProps) => {
  const infos = (
    isStay
      ? [classText, sleeps, beds, maxVehicleLength] // campsites are marked as stays in listing tile
      : !isCampground
      ? [classText, [seats, sleeps].filter(Boolean).join(', '), feet]
      : [campgroundSubtitle, sleeps, beds, maxVehicleLength]
  ).filter(Boolean);

  return (
    <Text
      component="span"
      variant={ETextStyleVariant.SmallRegular}
      className="text-secondary-base"
      data-testid={generateTestIdFrom(title || '', 'listing-info')}>
      {!hideDetails &&
        (infos.length
          ? infos.map((info, index) => {
              return (
                <Fragment key={index}>
                  {index > 0 && <span aria-hidden="true">&nbsp;•&nbsp;</span>}
                  <span>{info}</span>
                </Fragment>
              );
            })
          : loading && <SkeletonLine width="80%" className="bg-gray-200" />)}

      {location ? (
        <>
          {!hideDetails && <br />}
          {location}
        </>
      ) : (
        loading && <SkeletonLine width="60%" className="bg-gray-200" />
      )}
    </Text>
  );
};
